import { Context } from '@nuxt/types'
import { Inject } from '@nuxt/types/app'

/**
 * @param ctx
 * @param inject
 */
export default function (ctx: Context, inject: Inject) {
  const options = JSON.parse('{"modules":{"articles":true,"divisions":true,"regions":false}}')
  const plugin = {} as any

  `}`
  /** берем из static/_articles или получаем выбранную статю и новость */
  plugin.getPage = async (alias: string, config: any = {}) => {
    if (process.server) {
      try {
        const article = JSON.parse(require('fs').readFileSync('static/_articles/' + alias + '.json', 'utf8'))
        return article
      } catch (e) {
        const response = await ctx.$axios.$get(process.env.API_ENDPOINT + 'pages/default/view', {
          cache: true,
          requireToken: false,
          ...config,
          params: {
            appId: process.env.APP_ID,
            alias,
            ...config.params
          }
        })
        require('fs').writeFileSync(require('path').join('static/_articles', response.alias + '.json'), JSON.stringify(response))
        return JSON.parse(require('fs').readFileSync('static/_articles/' + alias + '.json', 'utf8'))
      }
    }
  }

  /** берем из static/_articles или получаем список статей и новостей */
  plugin.getPageList = async (typeId: string, limit: number, config: any = {}) => {
    try {
      const response = await ctx.$axios.$get(process.env.API_ENDPOINT + 'pages/default/category', {
        cache: true,
        requireToken: false,
        ...config,
        params: {
          appId: process.env.APP_ID,
          type_id: typeId,
          limit,
          ...config.params
        }
      })
      if (!require('fs').existsSync('static/_articles')) {
        require('fs').mkdirSync('static/_articles', { recursive: true });
      }
      require('fs').writeFileSync(require('path').join('static/_articles', typeId + '.json'), JSON.stringify(response))
      const result =  JSON.parse(require('fs').readFileSync('static/_articles/' + typeId + '.json', 'utf8'))
      return Promise.resolve(result.slice(0, limit))
    } catch (e) {
      if (!fs.existsSync('static/_articles')) {
        fs.mkdirSync('static/_articles', { recursive: true });
      }
      const result =  JSON.parse(require('fs').readFileSync('static/_articles/' + typeId + '.json', 'utf8'))
      return Promise.resolve(result.slice(0, limit))
    }
  }
  ``;

  ``;

  `}`
  /** берем из static/_divisions или получаем регион фссп */
  plugin.getRegionDivisions = async (regionId, config: any = {}) => {
    if (process.server) {
      return JSON.parse(require('fs').readFileSync('static/_divisions/region_' + regionId.toString().padStart(2, '0') + '.json', 'utf8'))
    }

    return ctx.$axios.$get(process.env.API_ENDPOINT + 'helper/fssp-region-info', {
      cache: true,
      requireToken: false,
      ...config,
      params: {
        region_id: regionId,
        ...config.params
      }
    })
  }

  /** берем из static/_divisions или получаем отделение фссп */
  plugin.getDivisionInfo =async  (divisionId, config: any = {}) => {
    if (process.server) {
      const divisionGroup = divisionId >> 7
      const divisionList = JSON.parse(require('fs').readFileSync('static/_divisions/index_' + divisionGroup + '.json', 'utf8'))
      const division = divisionList[divisionId.toString()]
      return Promise.resolve(division)
    }

    return ctx.$axios.$get(process.env.API_ENDPOINT + 'helper/fssp-division', {
      cache: true,
      requireToken: false,
      ...config,
      params: {
        id: divisionId,
        ...config.params
      }
    })
  }
  ``;

  inject('static', plugin)
}
